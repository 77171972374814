<template>
  <div class="footer">
    <div class="footer_con">
      <div class="footer_box">
        <div class="box_left">
          <div class="footer_call"><span>联系我们</span>--预约体验</div>
          <div class="dec"><span>电话：</span>186 1011 1732 </div>
          <div class="dec"><span>邮箱：</span>aihuashen@aihuashen.com</div>
          <div class="dec"><span>地址：</span>北京市海淀区中关村东路一号院爱化身大厦</div>
        </div>
        <div class="box_right">
          <div class="footer_logo"></div>
          <div class="code_list">
            <div class="code_item">
              <img src="@/assets/img/code_gzh.png" alt="" srcset="">
              <span>微信公众号 </span>
            </div>
            <div class="code_item">
              <img src="@/assets/img/code_wx.png" alt="" srcset="">
              <span>商务微信号</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy">
      <p class="footer_rights">
        © 2023 爱化身科技（北京）有限公司 All Rights Reserved <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021014010号-1</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footercom'
}
</script>

<style>
</style>